import React from 'react';
import {Grid, SvgIcon, Typography} from "@mui/material";
import {ReactComponent as LOGO} from '../logo.svg';

const TermsOfService: React.FC = () => {


    const toPass = {
        sx: {
            height: 'auto!important',
            filter: `drop-shadow(0 2px 2px rgba(114, 134, 71, 0.6))`,
            fontSize: 100
        }
    };

    return (
        <Grid container direction={'column'} gap={4} justifyContent={'space-around'}
              sx={{maxWidth: 700, margin: '0 auto', padding: 2}}>
            <Grid item>
                <SvgIcon viewBox="0 0 292 116" component={LOGO} {...toPass} inheritViewBox/>
            </Grid>
            <Grid item>
                <Typography variant={'h1'}>Terms of Service</Typography>
            </Grid>
            <Grid item>
                We use cookies for functionality and quality assurance of our user experience. We do not follow you
                anywhere or sell your data to anyone for any reason.
            </Grid>

            <Grid item>
                <Typography variant={'h1'}>Content Moderation</Typography>
            </Grid>
            <Grid item>
                Posting illicit, illegal or offensive content will get your booted.
                Repeatedly friend requesting someone rejects your friend request will also get your booted.
            </Grid>
        </Grid>
    );
};

export default TermsOfService;
