import React, {useEffect} from 'react';
import {EventCheckedIn, EventFullView} from "./EventDetails";
import {Box, LinearProgress, Typography} from "@mui/material";
import {useFocusedContext} from "../contexts/FocusedProvider";
import {useAuth} from "../allauth/auth";
import MyEvent from "../components/MyEvent";
import {Events} from "../object-actions/types/types";
import EventSongRequests from "../components/EventSongRequests";
import {useParams} from "react-router-dom";

interface EventHomeProps {
    viewMode: string;
}

export const EventHome: React.FC<EventHomeProps> = ({viewMode}) => {
    const me = useAuth()?.data?.user;
    const params = useParams();
    const id = params.id ? parseInt(params.id) : 0;

    const {
        error,
        focusedEvent, fetchEvent,
    } = useFocusedContext()

    useEffect(() => {
        fetchEvent(id)
    }, [id]);

    let canManage = false;
    let viewEl = null;
    if (error) {
        viewEl = <Box p={2}><Typography color={'error'} variant={'subtitle2'}>{error}</Typography></Box>
    } else if (!focusedEvent) {
        viewEl = <Box p={2} mt={7}><LinearProgress/></Box>;
    } else {
        // @ts-ignore
        canManage = focusedEvent.author.id === me.id || (focusedEvent.cohosts && focusedEvent?.cohosts.findIndex(rel => rel.id === me.id) > -1);
        if (viewMode === 'request-song') {
            viewEl = <EventSongRequests event_id={focusedEvent.id}/>
        } else if (canManage && viewMode !== 'public') {
            viewEl = <MyEvent entity={focusedEvent as Events}/>
        } else if (viewMode === 'checked-in') {
            viewEl = <EventCheckedIn entity={focusedEvent as Events} viewMode={viewMode}/>
        } else if (viewMode === 'active') {
            viewEl = <EventFullView entity={focusedEvent as Events} viewMode={viewMode}/>
        } else {
            viewEl = <EventFullView entity={focusedEvent as Events} viewMode={viewMode}/>
        }
    }

    return viewEl

};

export default EventHome;