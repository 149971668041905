import React from 'react';
import {ButtonPill} from "../forms/StyledFields";
import {Events} from "../object-actions/types/types";
import {Link} from "react-router-dom";
import {getSafeAreaPadding} from "../utils";

interface PlaylistCtaProps {
    entity: Events;
}

const PlaylistCta: React.FC<PlaylistCtaProps> = ({entity}) => {

    const topPadding = getSafeAreaPadding('paddingTop') + 15

    return <ButtonPill
        style={{
            position: 'absolute',
            right:10,
            top: topPadding,
            textAlign: 'center',
            zIndex:11
        }}
        size={'small'}
        component={Link} to={`/events/${entity.id}/playlists`}
        variant={'contained'}>Playlists</ButtonPill>
};

export default PlaylistCta;
