import React, {useState} from "react";
import {Box, Divider, Grid, LinearProgress, Typography,} from "@mui/material";
import {ChevronRight, FiberManualRecord,} from "@mui/icons-material";
import {ApiListResponse, Friendships, RelEntity} from "../object-actions/types/types";
import AvatarsGrouped from "../components/AvatarsGrouped";
import {useAuth} from "../allauth/auth";
import Friendship from "../components/Friendship";
import {PagiFilters, useData} from "../contexts/DataProvider";
import {fetchList} from "../hooks/useQuery";
import SongRequestList from "../components/activities/SongRequestList";
import EventCheckinList from "../components/activities/EventCheckinList";
import LikeList from "../components/activities/LikeList";
import {useNavigate} from "react-router-dom";

interface ActivityListProps {
    event_id?: string | number;
}

const ActivityList: React.FC<ActivityListProps> = ({event_id}) => {
    return (
        <Box mt={6} p={1} style={{minHeight: '100vh'}}>
            <Box>
                <Typography variant={"h1"} style={{textAlign: 'center'}} gutterBottom={true}>Notifications</Typography>
            </Box>
            <Box>
                <FriendsRequests />
                <SongRequestList />
                <EventCheckinList />
            </Box>
        </Box>
    );
};
const FriendsRequests: React.FC<ActivityListProps> = ({event_id}) => {
    const apiUrl = (event_id) ? `/api/friendships/by-event/${event_id}?status=pending,accepted` : `/api/friendships?status=pending,accepted`
    const me = useAuth()?.data?.user

    const [pagination, setPagination] = useState<PagiFilters>({offset: 0, limit: 10});
    const [key, setKey] = useState(apiUrl);

    const {
        apiResponse,
        isLoading
    } = useData<ApiListResponse<Friendships>>(key, pagination, (): any => fetchList(key, pagination));


    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => ({
            ...prevPagination,
            limit: limit,
            offset: offset
        }));
    }

    if (!me || isLoading) return <Box p={2}><LinearProgress/></Box>

    function renderSummary() {
        let finalStr = ''
        const avatars: RelEntity[] = [];
        if (apiResponse.results.length > 1) {
            const names = []
            for (let i = 0; i < apiResponse.results.length && i < 4; i++) {
                const entity = apiResponse.results[i] as Friendships
                const other = entity['author'].id === me.id ? entity['recipient'] : entity['author']

                if (other.img) {
                    avatars.push(other)
                    names.push(other.str)
                }
            }

            finalStr = names.join(', ')
            if (apiResponse.results.length > 0) {
                finalStr += `, +${apiResponse.results.length - names.length} others`
            }
        }
        return <Grid container p={2} mb={2} spacing={1}>
            <Grid item>
                <AvatarsGrouped
                    event_id={event_id}
                    entities={avatars}
                />
            </Grid>
            <Grid item style={{flexGrow: 1}}>
                <Typography variant={"h4"}>Friend requests</Typography>
                <Typography variant={"body2"} className="text-gray-500 font-normal">
                    {finalStr}
                </Typography>
            </Grid>
            <Grid item>
                <FiberManualRecord fontSize="small" color={"primary"}/>
                <ChevronRight fontSize="large"/>
            </Grid>
        </Grid>
    }


    return (
        <React.Fragment>
            {apiResponse.results.map((req) => {
                return <Friendship
                    key={req.id}
                    entity={req as Friendships}
                />
            })}
            {apiResponse.results.length > 0 && <Divider/>}
        </React.Fragment>
    );
}

export const RecentNotifications: React.FC<ActivityListProps> = ({event_id}) => {
    return (
        <Box>
            <SongRequestList event_id={event_id}/>
            <EventCheckinList event_id={event_id}/>
        </Box>
    );
}

export default ActivityList;
