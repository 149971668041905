import * as React from 'react';
import Router from "./Router";
import ObjectActionsProvider from "./object-actions/ObjectActionsProvider";
import {ThemeProvider} from "./theme/ThemeContext";
import {NavDrawerProvider} from "./NavDrawerProvider";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {AuthContextProvider} from "./allauth/auth";
import {SnackbarProvider} from "notistack";
import { getSafeAreaPadding } from './utils';

const params = new URLSearchParams(window.location.search)
let isInApp = params.get('appOS') || localStorage.getItem('appOS');
if (isInApp) {
    document.body.classList.add('isInApp');
    const el = document.getElementById("DevicePadding") ?? document.body
    localStorage.setItem("appOS", isInApp);
    const paddingTop = getSafeAreaPadding('paddingTop')
    if (paddingTop > 0) {
        el.style.paddingTop = `${paddingTop}px`;
    }

    const paddingBottom = getSafeAreaPadding('paddingBottom')
    if (paddingBottom > 0) {
        el.style.paddingBottom = `${paddingBottom}px`;
    }

}

export default function App() {

    return (<ThemeProvider>
            <SnackbarProvider maxSnack={4} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
                              style={{marginBottom: 50}}>
                <AuthContextProvider>
                    <NavDrawerProvider>
                        <ObjectActionsProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Router/>
                            </LocalizationProvider>
                        </ObjectActionsProvider>
                    </NavDrawerProvider>
                </AuthContextProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
}
